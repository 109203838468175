import moment from "moment-timezone";
import React from "react";
import { Button } from "react-bootstrap";
import { Lesson } from "../../../api/Lesson/Lesson";
import { Instructor } from "../../../api/Instructor/Instructor";
import { isNullOrUndefined } from "util";

interface IExcelReport {
	report: Lesson[];
}

const today = () => moment()
  .tz("America/Merida")
  .format("D [de] MMMM [-] hh:mm A");

const className = (name: string): string => {
  return isNullOrUndefined(name) || name === '' ? "N/D" : name;
}

const ExcelReport = ({ report }: IExcelReport) => {
	return (
<div></div>
	);
};

export default ExcelReport;
