exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/1.jpg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./images//2.jpg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./images/3.jpg"));

// Module
exports.push([module.id, ".studios {\n  position: relative;\n  margin: -6vh; }\n  .studios .col {\n    padding: 0px; }\n  .studios .card {\n    height: 100vh;\n    border-radius: 0px;\n    border: none;\n    background-blend-mode: darken; }\n  .studios__left {\n    background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover; }\n  .studios__right {\n    background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n    background-repeat: no-repeat;\n    background-size: cover; }\n  .studios__online {\n    background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n    background-repeat: no-repeat;\n    background-size: cover; }\n  .studios__body__image-row {\n    display: flex;\n    justify-content: center; }\n    .studios__body__image-row__image {\n      height: 80px;\n      max-width: 90%;\n      margin-top: 25vh;\n      margin-left: auto;\n      margin-right: auto;\n      filter: brightness(0) invert(1); }\n      .studios__body__image-row__image-fixed-height {\n        height: 120px; }\n  .studios__body__line {\n    margin-top: 100px;\n    border-bottom: 1px solid white;\n    width: 28vw;\n    min-width: 250px; }\n    .studios__body__line-large {\n      margin-top: 140px; }\n  .studios__body__button-row {\n    margin-top: 100px;\n    display: flex;\n    justify-content: center; }\n  .studios .btn {\n    background-color: black;\n    border-color: black;\n    width: 200px;\n    height: 50px;\n    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }\n  .studios .report-btn {\n    position: fixed;\n    right: 50px;\n    top: 120px;\n    z-index: 2; }\n", ""]);

